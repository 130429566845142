import { memo } from 'react'

export default memo<{ width: number; height: number; color?: string; className?: string }>(
  function OrusHorizontalLogo(props) {
    const { width, height, className } = props

    return (
      <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 82 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#a)">
          <path
            fill="#020034"
            d="M68.741 8.723v2.046a5.918 5.918 0 0 1 2.18-1.89 6.02 6.02 0 0 1 2.745-.656c1.517 0 2.866.385 4.047 1.156 1.192.77 2.118 1.812 2.777 3.124.669 1.313 1.003 2.75 1.003 4.312 0 1.573-.334 3.02-1.003 4.343-.66 1.312-1.58 2.354-2.761 3.124-1.182.771-2.51 1.157-3.984 1.157a5.972 5.972 0 0 1-2.808-.672 5.777 5.777 0 0 1-2.055-1.781l.095 1.953v5.499h-4.832V8.723h4.596Zm4.094 12.294c.7 0 1.344-.161 1.93-.484a3.68 3.68 0 0 0 1.427-1.453c.355-.635.533-1.39.533-2.265 0-.844-.178-1.583-.533-2.218a3.576 3.576 0 0 0-1.412-1.453 3.885 3.885 0 0 0-1.976-.516 3.61 3.61 0 0 0-1.96.563 3.92 3.92 0 0 0-1.397 1.515 4.346 4.346 0 0 0-.517 2.109c0 .812.177 1.541.533 2.187.355.635.831 1.13 1.427 1.484a3.74 3.74 0 0 0 1.945.531ZM54.405 8.207c2.018 0 3.53.698 4.533 2.093 1.004 1.386 1.506 3.36 1.506 5.921v8.686h-4.769v-8.576c0-1.281-.24-2.219-.721-2.812-.481-.604-1.145-.906-1.992-.906-.554 0-1.077.182-1.569.546-.48.354-.868.854-1.16 1.5-.283.646-.424 1.385-.424 2.219v8.03h-4.768V16.33c0-1.292-.24-2.234-.722-2.828-.47-.594-1.134-.89-1.992-.89-.554 0-1.072.182-1.552.546-.482.354-.868.86-1.161 1.516-.293.645-.44 1.38-.44 2.203v8.03h-4.768V8.722h4.518l.062 1.952c.43-.729 1.067-1.322 1.914-1.78.857-.459 1.814-.688 2.87-.688 1.266 0 2.327.276 3.184.828.868.552 1.538 1.36 2.008 2.421.722-1.124 1.532-1.947 2.431-2.468.9-.52 1.904-.781 3.012-.781ZM23.035 25.423c-1.527 0-2.876-.27-4.047-.812-1.171-.542-2.097-1.412-2.777-2.61-.68-1.207-1.02-2.754-1.02-4.64V8.724h4.8v8.482c0 2.521 1.015 3.781 3.044 3.781 2.039 0 3.058-1.26 3.058-3.78V8.723h4.784v8.639c0 1.885-.34 3.432-1.02 4.64-.68 1.197-1.604 2.067-2.776 2.609-1.17.541-2.52.812-4.046.812Z"
          />
          <path
            fill="#020034"
            fillRule="evenodd"
            d="M3.111 8.716h4.877a3.66 3.66 0 0 1 3.668 3.653v6.963c0 6.171-5.023 11.175-11.22 11.175H0v-4.713h.436c3.583 0 6.488-2.893 6.488-6.462v-5.904H3.111V8.716Z"
            clipRule="evenodd"
          />
          <ellipse cx={11.723} cy={4.52} fill="#1CD3DF" rx={3.039} ry={3.027} />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h81.493v32H0z" />
          </clipPath>
        </defs>
      </svg>
    )
  },
)
